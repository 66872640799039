/**
 * The OfflineBar component
 */
import React from "react";
import PropTypes from "prop-types";
import { Offline } from "react-detect-offline";
/**
 * OfflineBar stateless component
 * - Notifies user that the page is offline
 * - Turn app offline to see the component
 */
const OfflineBar = (props, context) => {
  return (
    <Offline polling={false}>
      <div className="offline-bar">
        <span>{context.t("Offline Message")}</span>
      </div>
    </Offline>
  );
};

/** define proptypes for the form */
OfflineBar.propTypes = {};

/**  define proptype for the 'translation' function  */
OfflineBar.contextTypes = {
  t: PropTypes.func,
};
export default OfflineBar;
