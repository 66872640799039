import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Page from "../components/Page";
import {
  getProfessionalUserAttributes,
  updateProfessionalUser,
  showProfessionalUser,
} from "../redux/professional_users";
import { listLocations } from "../redux/locations";
import {
  Alert,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  Input,
  Button,
  Spinner,
} from "reactstrap";
import Sidebar from "../components/Sidebar";
import Headline from "../components/Utils/Headline";
import { useLocationId } from "../utils/hooks";
import { languages } from "../components/UserProfileForm/UserProfileForm-component";

/**
 * UserEdit container, displays the user edit form
 */
const UserEdit = ({ match }, context) => {
  const dispatch = useDispatch();
  const userId = match?.params?.id;

  const locationId = useLocationId();
  const locations = useSelector((state) => state.locations.list);

  useEffect(() => {
    // TODO: refactor naming
    dispatch(showProfessionalUser({ locationId, professionalUserId: userId }));
    dispatch(listLocations());
  }, [locationId, userId, dispatch]);

  const user = useSelector((state) =>
    getProfessionalUserAttributes(state, userId)
  );

  const currentUser = useSelector(
    (state) => state.professionalAccount.currentUser
  );

  // Alert logic
  // TODO: move to alert component...
  const [visible, setVisible] = useState(false);
  const onDismiss = () => setVisible(false);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm();

  const onSubmit = (data) => {
    // Why 500ms wait?
    // setTimeout(() => {
    //   setVisible(true);
    // }, 500);

    dispatch(
      updateProfessionalUser({
        user: {
          ...data,
          client_id: user.client_id,
        },
        locationId,
        userId: userId,
      })
    );
  };

  // TODO: Refactor that to a more central thing (like languages)
  const roles = [
    "coaching",
    "cook",
    "unit_manager_light",
    "unit_manager",
    "client_manager",
    "super_admin",
  ];

  const updateError = useSelector(
    (state) => state.professionalUsers.updateError
  );

  return (
    <Page id="user-edit" title="User Edit" noCrawl className="mt-5">
      <Container fluid className="d-flex">
        <Sidebar defaultCollapsed />
        <main className="flex-fill">
          <Row>
            <Col>
              <Link
                to={`/locations/${locationId}/users/${userId}`}
                className="btn btn-light"
              >
                <i className="far fa-arrow-left me-4" />
                <span>{context.t("Back")}</span>
              </Link>
              {!updateError && (
                <Alert
                  className="my-3"
                  color="success"
                  isOpen={visible}
                  toggle={onDismiss}
                >
                  {context.t("User updated successfully!")}
                </Alert>
              )}
              {updateError && (
                <Alert
                  className="my-3"
                  color="primary"
                  isOpen={visible}
                  toggle={onDismiss}
                >
                  {context.t("There was an error processing your request.")}
                </Alert>
              )}
            </Col>
          </Row>
          <Row>
            <Col className="d-flex justify-content-center">
              {user && (
                <article>
                  <Headline headline={user?.name} />
                  <Card className="mt-4 spaced-content">
                    <CardBody className="p-4">
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <FormGroup>
                          <Label>{context.t("Nickname")}</Label>
                          <Input
                            {...register("nickname")}
                            defaultValue={user?.nickname}
                            invalid={errors?.nickname}
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label>{context.t("Name")}</Label>
                          <Input
                            {...register("name")}
                            defaultValue={user?.name}
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label>{context.t("Email")}</Label>
                          <Input
                            type="email"
                            {...register("email")}
                            defaultValue={user?.email}
                            required
                            invalid={errors?.email}
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label>{context.t("Language")}</Label>
                          <Input
                            type="select"
                            id="language"
                            {...register("language")}
                            defaultValue={user?.language}
                            invalid={errors?.language}
                          >
                            {Object.keys(languages).map((language) => (
                              <option
                                key={language}
                                value={language}
                                selected={language === user?.language}
                              >
                                {languages[language]}
                              </option>
                            ))}
                          </Input>
                        </FormGroup>
                        {(currentUser?.role === "client_manager" ||
                          currentUser?.role === "super_admin") && (
                          <section>
                            <FormGroup>
                              <Label>{context.t("Locations")}</Label>
                              <Input
                                type="select"
                                className="form-control"
                                {...register("location")}
                                // How should that work with multiple? Doesn't seem to ever did
                                multiple
                                defaultValue={user.location}
                                invalid={errors?.location}
                              >
                                {locations.map((location) => (
                                  <option key={location?.id}>
                                    {location?.id} - {location?.attributes.name}
                                  </option>
                                ))}
                              </Input>
                            </FormGroup>
                            <FormGroup>
                              <Label>{context.t("Role")}</Label>
                              <Input
                                type="select"
                                className="form-control"
                                {...register("role")}
                                defaultValue={user.role}
                                invalid={errors?.role}
                              >
                                {roles?.map((role) => (
                                  <option key={role}>{role}</option>
                                ))}
                              </Input>
                            </FormGroup>
                          </section>
                        )}

                        <div className="d-flex mt-4">
                          <Button
                            color="primary"
                            type="submit"
                            className="flex-fill"
                            disabled={isSubmitting}
                          >
                            {isSubmitting && <Spinner />}
                            <span>{context.t("Save")}</span>
                          </Button>
                        </div>
                      </form>
                    </CardBody>
                  </Card>
                </article>
              )}
            </Col>
          </Row>
        </main>
      </Container>
    </Page>
  );
};

/**  define proptype for the 'translation' function  */
UserEdit.contextTypes = {
  t: PropTypes.func,
};

/** export the component as redux connected component */
export default UserEdit;
