/*
Replace
professionalUser with your resource Name :) Take care about different writings!
/!\ ProfessionalUser professionalUser ProfessionalUsers professionalUsers /!\
Keep an eye on pluralized namings in here :)

*/
/**
 *  Sagas for the ProfessionalUsers resource managment using Swagger client
 *
 * Each saga watcher intercepts a trigger action, does the asyncrhonous work in the respective worker saga and dispatches a success or a failure action.
 * Fetch calls are made via the swagger tags interface requests
 *
 */

import { call, put, takeEvery, select } from "redux-saga/effects";
import buildHeaders from "../../utils/buildHeaders";
import { actions } from "./index";
import professionalAccountActions from "../professional_account/actions";
import { storeIncluded } from "../dictionarySagas";
import apiClient, { applyHeaders } from "../../utils/apiSwaggerRequest";
import DataLayerHelper from "../../utils/dataLayer";
import { setLanguage } from "redux-i18n";

/** Worker Sagas */

/** List Saga
 *  @description: connects to the getProfessionalUser operation
 */
export function* list(action) {
  const { locationId } = action.payload;
  const currentUser = yield select(
    (state) => state.professionalAccount.currentUser
  );
  let headers = buildHeaders(currentUser);

  try {
    const SwaggerClient = yield call(apiClient);
    const payload = yield call(
      SwaggerClient.apis.ProfessionalUsers.listProfessionalUsers,
      { location_id: locationId },
      { requestInterceptor: applyHeaders(headers) }
    );
    if (payload.obj.included)
      yield* storeIncluded({ payload: payload.obj.included });
    yield put({ type: actions.listSuccess, payload: payload });
  } catch (e) {
    yield put({ type: actions.listFail, payload: e });
    DataLayerHelper.addErrorEvent(
      "Load ProfessionalUser List",
      e.error || e.message || "ProfessionalUser Error"
    );
  }
}

/** Show Saga
 *  @description: connects to the showProfessionalUser operation
 *  @param {number} action.payload the ProfessionalUser id
 */
export function* show(action) {
  // TODO: its a mess, refactor to use same names (userId vs profes...) / patterns
  const { locationId, professionalUserId } = action.payload;
  const currentUser = yield select(
    (state) => state.professionalAccount.currentUser
  );
  let headers = buildHeaders(currentUser);
  try {
    const SwaggerClient = yield call(apiClient);
    const payload = yield call(
      SwaggerClient.apis.ProfessionalUsers.showProfessionalUser,
      { id: professionalUserId, location_id: locationId },
      { requestInterceptor: applyHeaders(headers) }
    );
    if (payload.obj.included)
      yield* storeIncluded({ payload: payload.obj.included });
    yield put({ type: actions.showSuccess, payload: payload.obj.data });
  } catch (e) {
    yield put({ type: actions.showFail, payload: e });
  }
}

export function* create(action) {
  const { user } = action.payload;
  const currentUser = yield select(
    (state) => state.professionalAccount.currentUser
  );
  let headers = buildHeaders(currentUser);
  try {
    const SwaggerClient = yield call(apiClient);
    let payload = yield call(
      SwaggerClient.apis.ProfessionalUsers.createProfessionalUser,
      { user },
      { requestInterceptor: applyHeaders(headers) }
    );

    yield put({ type: actions.createSuccess, payload: payload?.obj?.data });

    if (payload?.obj?.included) {
      yield* storeIncluded({ payload: payload.obj.included });
    }

    if (action.payload.callback) {
      action.payload.callback();
    }

    DataLayerHelper.addSuccessEvent(
      "Create ProfessionalUser",
      `data to log, maybe id?`
    );
  } catch (e) {
    yield put({ type: actions.createFail, payload: e });
  }
}
export function* update(action) {
  const { userId, locationId, user } = action.payload;
  const currentUser = yield select(
    (state) => state.professionalAccount.currentUser
  );
  const headers = buildHeaders(currentUser);
  try {
    const SwaggerClient = yield call(apiClient);
    let payload = yield call(
      SwaggerClient.apis.ProfessionalUsers.updateProfessionalUser,
      { id: userId, location_id: locationId, user },
      { requestInterceptor: applyHeaders(headers) }
    );
    yield put({ type: actions.updateSuccess, payload: payload.obj.data });

    // Trigger update if its current user that was updated.
    // NOTE: it has a slightly different structure then the JSON-API format
    if (currentUser.id === parseInt(payload.obj?.data?.id)) {
      yield put({
        type: professionalAccountActions.loginSuccess,
        payload: { data: { ...currentUser, ...payload.obj.data.attributes } },
      });
      // Update redux-i18n language
      yield put(
        setLanguage(
          payload?.obj?.data?.attributes?.language || currentUser.language
        )
      );
    }

    // yield* storeIncluded({ payload: payload.obj.included });

    if (action.payload.callback) {
      action.payload.callback();
    }

    DataLayerHelper.addSuccessEvent("Updated ProfessionalUser", `${userId}`);
  } catch (e) {
    yield put({ type: actions.updateFail, payload: e });
  }
}

/**
 * Saga Watchers
 * The exported list of sagas registered. When one of the action types is dispatched
 * the related worker saga is invoked.
 * Each saga is executed in a different thread
 */
function* transactionsSaga() {
  yield takeEvery(actions.list, list);
  yield takeEvery(actions.show, show);
  yield takeEvery(actions.create, create);
  yield takeEvery(actions.update, update);
}
export default transactionsSaga;
