/*
Replace
dishBundle with your resource Name :) Take care about different writings!
/!\ DishBundle dishBundle DishBundles dishBundles /!\
Keep an eye on pluralized namings in here :)

*/

import { createSlice, createSelector } from "@reduxjs/toolkit";
import {
  defaultList,
  defaultListFail,
  defaultListSuccess,
  defaultShow,
  defaultShowFail,
  defaultShowSuccess,
  defaultUpdate,
  defaultUpdateSuccess,
  defaultUpdateFail,
  defaultCreate,
  defaultCreateSuccess,
  defaultCreateFail,
  defaultAddToDictionary,
} from "../defaultReducers";
import { defaultInitialState } from "../defaultInitialState";

export const dishBundleSlice = createSlice({
  name: "dishBundles",
  initialState: { ...defaultInitialState },
  reducers: {
    list: defaultList,
    listSuccess: defaultListSuccess,
    listFail: defaultListFail,
    show: defaultShow,
    showSuccess: defaultShowSuccess,
    showFail: defaultShowFail,
    create: defaultCreate,
    createSuccess: defaultCreateSuccess,
    createFail: defaultCreateFail,
    update: defaultUpdate,
    updateSuccess: defaultUpdateSuccess,
    updateFail: defaultUpdateFail,
    addToDictionary: defaultAddToDictionary,
  },
});

export const {
  list: listDishBundles,
  show: showDishBundle,
  create: createDishBundle,
  update: updateDishBundle,
} = dishBundleSlice.actions;

export const actions = dishBundleSlice.actions;
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const dishBundles = (state) =>
  state.dishBundles.idsList.map((id) => state.dishBundles.dictionary[id]);
export const dishBundle = (state) =>
  state.dishBundles.showId &&
  state.dishBundles.dictionary[state.dishBundles.showId];
export const dishBundleAttributes = (state) => dishBundle(state)?.attributes;
export const dishBundleRelationships = (state) =>
  dishBundle(state)?.relationships;
export const dishBundleId = (state) => state.dishBundles.showId;

export const getDishBundle = createSelector(
  (state) => state.dishBundles.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id];
  }
);

export const getDishBundleAttributes = createSelector(
  (state) => state.dishBundles.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id]?.attributes;
  }
);

export const getDishBundleRelationships = createSelector(
  (state) => state.dishBundles.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id]?.relationships;
  }
);

export const getDishBundles = createSelector(
  (state) => state.dishBundles.dictionary,
  (_, ids) => ids,
  (dictionary, ids) => {
    return ids && dictionary && ids.map((id) => dictionary[id]);
  }
);

export default dishBundleSlice.reducer;
