import { translations } from "../locales/translations";

// Auto create translation keys
// context.t("Sunday")
// context.t("Monday")
// context.t("Tuesday")
// context.t("Wednesday")
// context.t("Thursday")
// context.t("Friday")
// context.t("Saturday")
const weekdays = (locale) =>
  [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ].map((day) => translations[locale][day] || day);

// Auto create translation keys
// context.t("Su")
// context.t("Mo")
// context.t("Tu")
// context.t("We")
// context.t("Th")
// context.t("Fr")
// context.t("Sa")
const weekdaysAbbr = (locale) =>
  ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"].map(
    (day) => translations[locale][day] || day
  );

// Auto create translation keys
// context.t("January")
// context.t("February")
// context.t("March")
// context.t("April")
// context.t("May")
// context.t("June")
// context.t("July")
// context.t("August")
// context.t("September")
// context.t("October")
// context.t("November")
// context.t("December")
const months = (locale) =>
  [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ].map((month) => translations[locale][month] || month);

// Auto create translation keys
// context.t("Jan")
// context.t("Feb")
// context.t("Mar")
// context.t("Apr")
// context.t("May")
// context.t("Jun")
// context.t("Jul")
// context.t("Aug")
// context.t("Sep")
// context.t("Oct")
// context.t("Nov")
// context.t("Dec")
const monthsAbbr = (locale) =>
  [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ].map((month) => translations[locale][month] || month);

const momentTranslations = (locale) => {
  return {
    months: months(locale),
    monthsShort: monthsAbbr(locale),
    monthsParseExact: true,
    weekdays: weekdays(locale),
    weekdaysShort: weekdaysAbbr(locale),
    weekdaysMin: weekdaysAbbr(locale),
    weekdaysParseExact: true,
    longDateFormat: {
      LT: "HH:mm",
      LTS: "HH:mm:ss",
      L: "DD/MM/YYYY",
      LL: "D MMMM YYYY",
      LLL: "D MMMM YYYY HH:mm",
      LLLL: "dddd D MMMM YYYY HH:mm",
    },
    week: {
      dow: 1,
    },
  };
};

export default momentTranslations;
