/**
 * The Lesson container
 */
import { useLocation } from "../utils/hooks";
import { Switch } from "react-router-dom";

const Location = ({ children }, context) => {
  useLocation();

  return <Switch>{children}</Switch>;
};

export default Location;
