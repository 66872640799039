/**
 * The Login component
 */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import classNames from "classnames";
import PropTypes from "prop-types";
import MainMenu from "../MainMenu";
import { initials } from "../../utils/formatters";

import Avatar from "../Avatar/Avatar-component";

/** Login stateless component to render the login fields in the Account container */
const Sidebar = ({ defaultCollapsed }, context) => {
  const currentUser = useSelector(
    (state) => state?.professionalAccount?.currentUser
  );

  const [collapsed, setCollapsed] = useState(defaultCollapsed);

  useEffect(() => {
    setCollapsed(defaultCollapsed);
  }, [defaultCollapsed, setCollapsed]);

  if (!currentUser) {
    return "";
  }

  return (
    <aside
      className={classNames("sidebar", { collapsed })}
      // onMouseOver={() => setCollapsed(false)}
      // onMouseOut={() => setCollapsed(defaultCollapsed)}
    >
      <section className="sidebar-user mb-4">
        <Avatar initials={initials(currentUser.name)} />
        <br />
        <span className="mt-2">{currentUser.name}</span>
        {currentUser.role && (
          <>
            <br />
            <span className="badge rounded-pill text-bg-primary bg-opacity-25 text-nowrap">
              {context.t(currentUser.role)}
            </span>
          </>
        )}
      </section>
      <section className="sidebar-main-menu">
        <MainMenu collapsed={collapsed} />
      </section>
    </aside>
  );
};

Sidebar.propTypes = {
  defaultCollapsed: PropTypes.bool,
};

Sidebar.defaultProps = {
  defaultCollapsed: false,
};

Sidebar.contextTypes = {
  t: PropTypes.func,
};

export default Sidebar;
