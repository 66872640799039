/**
 * The FieldInput component
 */
import React from "react";
import { FormGroup, Input, Button } from "reactstrap";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";

/**
 * List of languages supported
 */
export const languages = {
  en: "English",
  de: "Deutsch",
};

/**
 * Form to handle update and create companies
 */
const UserProfileForm = ({ onSubmit, user }, context) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormGroup>
        <label className="me-2 mb-2" htmlFor="current_password">
          {context.t("Language")}
        </label>
        <Input
          type="select"
          id="language"
          {...register("language", {
            required: "required",
          })}
          defaultValue={user?.language}
          invalid={errors.language}
        >
          {Object.keys(languages).map((language) => (
            <option key={language} value={language}>
              {languages[language]}
            </option>
          ))}
        </Input>
      </FormGroup>
      <FormGroup>
        <label className="me-2 mb-2" htmlFor="current_password">
          {context.t("Password")}
        </label>
        <Input
          id="password"
          {...register("password", {
            minLength: {
              value: 5,
              message: "min length is 5",
            },
          })}
          type="password"
          invalid={errors.current_password}
        />
      </FormGroup>
      <FormGroup>
        <label className="me-2 mb-2" htmlFor="password">
          {context.t("Password (confirmation)")}
        </label>
        <Input
          id="password_confirmation"
          {...register("password_confirmation", {
            minLength: {
              value: 5,
              message: "min length is 5",
            },
          })}
          type="password"
          className="mb-4"
          invalid={errors.password}
        />
      </FormGroup>
      <p className="text-end mb-0">
        <Button color="primary" type="submit" disabled={isSubmitting}>
          {context.t("save")}
        </Button>
      </p>
    </form>
  );
};

/**  define proptype for the 'translation' function  */
UserProfileForm.contextTypes = {
  t: PropTypes.func,
};

/** define proptypes for the form */
UserProfileForm.propTypes = {
  /** function called on submit */
  onSubmit: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

/** export the form as reduxForm component to bind it to the redux store */
export default UserProfileForm;
