/**
 * The Login component
 */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Nav } from "reactstrap";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import classNames from "classnames";
import AccessControl from "../AccessControl";
import { routes } from "../../routes/Root";
import { useLocationId } from "../../utils/hooks";
import { listLocations } from "../../redux/locations";

/** Login stateless component to render the login fields in the Account container */
const MainMenu = ({ collapsed }, context) => {
  const dispatch = useDispatch();
  const currentUser = useSelector(
    (state) => state?.professionalAccount?.currentUser
  );
  const locationIds = useSelector(
    (state) => state.locations.loadedList && state.locations.idsList
  );
  // const locationIds = ["14", "12"];

  const locationId =
    useLocationId() || (locationIds.length === 1 && locationIds[0]);

  useEffect(() => {
    if (locationIds) {
      return;
    }

    dispatch(listLocations());
  }, [dispatch, locationIds]);

  if (!currentUser) {
    return "";
  }

  return (
    <Nav className={classNames("main-menu", { collapsed })}>
      <NavLink
        to={routes.dashboard}
        exact
        className="btn btn-light"
        activeClassName="bg-primary text-white"
      >
        <i className="far fa-th" aria-hidden="true"></i>
        <span className="ms-4">{context.t("Dashboard")}</span>
      </NavLink>
      <AccessControl
        role={currentUser?.role}
        module="menuPlanner"
        right="index"
      >
        <NavLink
          to={
            locationIds?.length > 1
              ? "/locations"
              : `/locations/${locationId}/menu-planner`
          }
          className="btn btn-light"
          activeClassName="bg-primary text-white"
        >
          <i className="far fa-book-blank" aria-hidden="true"></i>
          <span className="ms-4">{context.t("Menu Planner")}</span>
        </NavLink>
      </AccessControl>
      <AccessControl
        role={currentUser?.role}
        module="userManagement"
        right="index"
      >
        <NavLink
          to={
            locationIds?.length > 1
              ? "/locations"
              : `/locations/${locationId}/users`
          }
          className="btn btn-light"
          activeClassName="bg-primary text-white"
        >
          <i className="far fa-users" aria-hidden="true"></i>
          <span className="ms-4">{context.t("User Management")}</span>
        </NavLink>
      </AccessControl>
      <AccessControl role={currentUser?.role} module="academy" right="index">
        <NavLink
          to="/academy/courses"
          className="btn btn-light"
          activeClassName="bg-primary text-white"
        >
          <i className="far fa-graduation-cap" aria-hidden="true"></i>
          <span className="ms-4">{context.t("Academy")}</span>
        </NavLink>
      </AccessControl>
      <AccessControl role={currentUser?.role} module="profile" right="update">
        <NavLink
          to="/profile"
          className="btn btn-light"
          activeClassName="bg-primary text-white"
        >
          <i className="far fa-gear" aria-hidden="true"></i>
          <span className="ms-4">{context.t("Profile")}</span>
        </NavLink>
      </AccessControl>
    </Nav>
  );
};

MainMenu.propTypes = {
  collapsed: PropTypes.bool,
};

MainMenu.defaultProps = {
  collapsed: false,
};

MainMenu.contextTypes = {
  t: PropTypes.func,
};

export default MainMenu;
