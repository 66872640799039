import { sortBy } from "lodash";

const csvOptions = (line) =>
  Object.fromEntries(
    line
      .replaceAll('"', "")
      .split("\n")
      .map((o) => o.split("="))
  );

const normalizeValue = (v) => v.replace(/(N\/A)|(\")/g, "");

const hashCode = (s) =>
  Math.abs(
    [...s].reduce((hash, c) => (Math.imul(31, hash) + c.charCodeAt(0)) | 0, 0)
  );

export const BOM = new Uint8Array([0xef, 0xbb, 0xbf]);

export const valueType = (v) => {
  if (!isNaN((v || "").toString().replace(",", "."))) {
    return "numeric";
  }

  if (v.match(/^\d{4}-\d{2}-\d{2}$/)) {
    return "date";
  }

  return "string";
};

export const extractHeaders = (entry, order = []) => {
  const keys = sortBy(
    Object.keys(entry),
    (k) => order.indexOf(k) + 1 || order.length + 1
  );

  return keys.reduce((acc, value) => {
    acc[value] = valueType(entry[value]);
    return acc;
  }, {});
};

export const rowKey = (e) =>
  hashCode(
    [
      e.date_of_serving,
      e.ingredient_id,
      e.recipe_id,
      e.dish_id,
      e.required_amount,
    ].join("-")
  );

const fetchAndParseCSV = (url, options = {}) => {
  return (
    fetch(url, {
      method: "get",
      headers: { "content-type": "text/csv;charset=UTF-8" },
      ...options,
    })
      // NOTE: response.text() returns in utf-8 which is not the correct encoding yet
      .then((response) => response.arrayBuffer())
      .then((buffer) => {
        // Fix csv file encoding
        const decoder = new TextDecoder("iso-8859-1");
        const csv = decoder.decode(buffer);
        const lines = csv.split("\n");
        // TODO: maybe find better matcher
        const separator = lines[0].match("sep=")
          ? csvOptions(lines.shift())["sep"]
          : ",";
        const headers = lines.shift().split(separator);
        const types = headers.reduce(
          (acc, curr) => ((acc[curr] = "string"), acc),
          {}
        );
        const separatorRegExp = new RegExp(
          `${separator}(?=(?:(?:[^"]*"){2})*[^"]*$)`
        );

        const jsonData = [];
        for (let i = 0; i < lines.length; i++) {
          const line = lines[i].split(separatorRegExp);
          const entry = {};

          for (let j = 0; j < headers.length; j++) {
            const value = normalizeValue(line[j] || "");
            entry[headers[j]] = value;

            if (value) {
              types[headers[j]] = valueType(value);
            }
          }

          // Add uniq key
          entry.key = i;

          jsonData.push(entry);
        }

        return { headers: types, rows: jsonData, totalCount: jsonData.length };
      })
  );
};

export default fetchAndParseCSV;
