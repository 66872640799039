/** ----- apiSwaggerRequest.js -----
 *  File: apiSwaggerRequest.js
 *
 *  Purpose: providing helper functions to perform API requests to ease out
 *  error handling through swagger client
 *----------------------------- */
import Swagger from "swagger-client";
import { API_URL } from "./apiRequest";
let swaggerClient = null;

/**
 * The swagger client. We make this call to load the swagger json
 */
const apiSwaggerRequest = () => {
  if (!swaggerClient) {
    swaggerClient = Swagger({ url: `${API_URL}/api-docs/v1/swagger.json` });
  }

  return swaggerClient;
};

/**
 * Request interceptor used to add headers to the request
 * @param {Object} headers the headers to apply
 * @returns a request object
 */
export const applyHeaders = (headers) => (req) => {
  if (headers) {
    Object.keys(headers).map(
      (headerKey) => (req.headers[headerKey] = headers[headerKey])
    );
  }
  return req;
};

export default apiSwaggerRequest;
