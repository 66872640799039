/**
 * Used for access control management
 * @param {Object} props the props passed to the page.
 */

export const rightsByRole = (role) => {
  switch (role) {
    case "cook":
      // roles for chef
      return {
        academy: {
          read: true,
          index: true,
        },
        menuPlanner: {
          read: true,
          index: true,
          update: false,
          create: false,
        },
        profile: {
          read: true,
          update: true,
          index: true,
        },
        userManagement: {
          read: false,
          index: false,
          update: false,
        },
        locations: {
          read: false,
          update: false,
          index: false,
        },
      };
    case "coaching":
      // roles for coaching
      return {
        academy: {
          read: true,
          index: true,
        },
        menuPlanner: {
          read: false,
          index: false,
          update: false,
          create: false,
        },
        profile: {
          read: true,
          update: true,
          index: true,
        },
        userManagement: {
          read: false,
          index: false,
          update: false,
        },
        locations: {
          read: false,
          update: false,
          index: false,
        },
      };
    case "unit_manager":
      // roles for manager
      return {
        academy: {
          read: true,
          index: true,
        },
        menuPlanner: {
          read: true,
          index: true,
          update: true,
          create: true,
        },
        profile: {
          read: true,
          update: true,
          index: true,
        },
        userManagement: {
          read: true,
          index: true,
          update: true,
        },
        locations: {
          read: true,
          update: true,
          index: true,
        },
      };
    case "unit_manager_light":
      // roles for manager light
      return {
        academy: {
          read: true,
          index: true,
        },
        menuPlanner: {
          read: true,
          index: true,
          update: true,
          create: false,
        },
        profile: {
          read: true,
          update: true,
          index: true,
        },
        userManagement: {
          read: true,
          index: true,
          update: true,
        },
        locations: {
          read: true,
          update: true,
          index: true,
        },
      };
    case "client_manager":
      // roles for client manager
      return {
        academy: {
          read: true,
          index: true,
        },
        menuPlanner: {
          read: true,
          index: true,
          update: true,
          create: true,
        },
        profile: {
          read: true,
          update: true,
          index: true,
        },
        userManagement: {
          read: true,
          index: true,
          update: true,
        },
        locations: {
          read: true,
          update: true,
          index: true,
        },
      };
    case "super_admin":
      // roles for super admin
      return {
        academy: {
          read: true,
          index: true,
        },
        menuPlanner: {
          read: true,
          index: true,
          update: true,
          create: true,
        },
        profile: {
          read: true,
          update: true,
          index: true,
        },
        userManagement: {
          read: true,
          index: true,
          update: true,
        },
        locations: {
          read: true,
          update: true,
          index: true,
        },
      };
    default:
      // roles any other role
      return {
        academy: {
          read: true,
          index: true,
        },
        menuPlanner: {
          read: false,
          index: false,
          update: false,
        },
        profile: {
          read: true,
          update: true,
          index: true,
        },
        userManagement: {
          read: false,
          index: false,
          update: false,
        },
        locations: {
          read: false,
          update: false,
          index: false,
        },
      };
  }
};

export const canAccess = (role, module, right) =>
  rightsByRole(role)[module][right];

const AccessControl = ({
  children,
  role,
  module,
  right,
  restrictedComponent,
}) => {
  const roleRights = rightsByRole(role);

  if (roleRights[module] && roleRights[module][right]) {
    return children;
  }

  return restrictedComponent || null;
};

export default AccessControl;
