/** ----- tokenCookies.js -----
 *  File: tokenCookies.js
 *
 *  Purpose: handling cookies regarding user tokens from api
 *
 *-----------------------------*/
import Cookies from "universal-cookie";

const cookieHandler = new Cookies();

export function setTokenCookies(headers) {
  if (headers["access-token"])
    cookieHandler.set("access-token", headers["access-token"], {
      sameSite: true,
      path: "/",
    });
  if (headers["client"])
    cookieHandler.set("client", headers["client"], {
      sameSite: true,
      path: "/",
    });
  if (headers["expiry"])
    cookieHandler.set("expiry", headers["expiry"], {
      sameSite: true,
      path: "/",
    });
  if (headers["token-type"])
    cookieHandler.set("token-type", headers["token-type"], {
      sameSite: true,
      path: "/",
    });
  if (headers["uid"])
    cookieHandler.set("uid", headers["uid"], { sameSite: true, path: "/" });
}

export function getTokenCookies() {
  let accessToken = cookieHandler.get("access-token");
  if (!accessToken) return false;
  else
    return {
      "access-token": accessToken,
      client: cookieHandler.get("client"),
      expiry: cookieHandler.get("expiry"),
      "token-type": cookieHandler.get("token-type"),
      uid: cookieHandler.get("uid"),
    };
}

export function deleteTokenCookies() {
  cookieHandler.remove("access-token", { sameSite: true, path: "/" });
  cookieHandler.remove("client", { sameSite: true, path: "/" });
  cookieHandler.remove("expiry", { sameSite: true, path: "/" });
  cookieHandler.remove("token-type", { sameSite: true, path: "/" });
  cookieHandler.remove("uid", { sameSite: true, path: "/" });
}

/** exports all actions as default */
const tokenCookies = {
  setTokenCookies: setTokenCookies,
  getTokenCookies: getTokenCookies,
  deleteTokenCookies: deleteTokenCookies,
};

export default tokenCookies;
