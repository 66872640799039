/**
 * The Login component
 */
import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Modal, ModalBody } from "reactstrap";

/** Login stateless component to render the login fields in the Account container */
const Avatar = ({ initials, src, size, className, zoomable }) => {
  const [zoomed, setZoomed] = useState(false);

  return (
    <>
      {zoomed && (
        <Modal
          size={size === "sm" ? "sm" : "lg"}
          isOpen={zoomed}
          toggle={() => setZoomed(!zoomed)}
        >
          <ModalBody className="p-1 rounded">
            <img src={src} className="w-100 rounded" />
          </ModalBody>
        </Modal>
      )}
      <figure
        onClick={() => zoomable && src && setZoomed(true)}
        className={classNames("avatar", `avatar-${size}`, className, {
          "cursor-pointer": zoomable,
        })}
        style={{ backgroundImage: src ? `url('${src}')` : "none" }}
      >
        {initials && <span>{initials}</span>}
      </figure>
    </>
  );
};

Avatar.propTypes = {
  initials: PropTypes.string,
  src: PropTypes.string,
  size: PropTypes.string,
  className: PropTypes.string,
  zoomable: PropTypes.bool,
};

Avatar.defaultProps = {
  initials: null,
  src: null,
  size: "default",
  className: "",
  zoomable: true,
};

export default Avatar;
