import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const Headline = ({ subline, headline, className }) => (
  <h1 className={classNames(className, "lh-1")}>
    {subline && (
      <>
        <small className="text-primary fs-6 text-uppercase">{subline}</small>
        <br />
      </>
    )}
    {headline}
  </h1>
);

Headline.propTypes = {
  subline: PropTypes.string,
  headline: PropTypes.string,
  className: PropTypes.string,
};

Headline.defaultProps = {
  subline: "",
  headline: "",
  className: "",
};

export default Headline;
